import React from 'react';
import ShowcasePage from './ShowcasePage';

import Thumbnail1 from './assets/animation/1.jpg';
import Thumbnail2 from './assets/animation/2.jpg';
import Thumbnail3 from './assets/animation/3.jpg';
import Thumbnail4 from './assets/animation/4.jpg';
import Thumbnail5 from './assets/animation/5.jpg';
import Thumbnail6 from './assets/animation/6.jpg';
import Thumbnail7 from './assets/animation/7.png';
import Thumbnail8 from './assets/animation/8.png';
import Thumbnail9 from './assets/animation/9.png';

function Animation() {
	return <ShowcasePage
		videoId = "9LTWn8_t0Q8"
		title="Nostalgia (2020)"
		description="An aging gentleman receives the opportunity to relive his past"
		thumbnails={[
			{img: Thumbnail1, url: 'vdO0GU90vrw'},
			{img: Thumbnail2, url: 'vFX1K4MQimA'},
			{img: Thumbnail3, url: 'pXk0e0bIXGk'},
			{img: Thumbnail4, url: 'HxM_RobA7o8'},
			{img: Thumbnail5, url: 'UywoDv6yZKQ'},
			{img: Thumbnail6, url: '5usyZAB7lI8'},
			{img: Thumbnail7, url: 'GZyO7sUnM6U'},
			{img: Thumbnail8, url: 'iKelRduzXr8'},
			{img: Thumbnail9, url: 'n820uVsTG8M'}
		]}
	/>
}

export default Animation;
import React from 'react';
import ShowcasePage from './ShowcasePage';

import Thumbnail1 from './assets/promotional/1.jpg';
import Thumbnail2 from './assets/promotional/2.png';
import Thumbnail3 from './assets/promotional/3.png';
import Thumbnail4 from './assets/promotional/4.jpg';
import Thumbnail5 from './assets/promotional/5.png';
import Thumbnail6 from './assets/promotional/6.png';
import Thumbnail7 from './assets/promotional/7.png';
import Thumbnail8 from './assets/promotional/8.png';
import Thumbnail9 from './assets/promotional/9.png';

function Promotional() {
	return <ShowcasePage
		videoId = "FzgvLMfyLmg"
		title="Cleveland Way (2019)"
		description="A promotional video made to celebrate the 50th anniversary of the Cleveland Way trail in the North Yorkshire Moors."
		thumbnails={[
			{img: Thumbnail1, url: '6yQEX60w6ZE'},
			{img: Thumbnail2, url: 'Lb_NZVYF4VU'},
			{img: Thumbnail3, url: 'Q7K5XEmDNxA'},
			{img: Thumbnail4, url: '9qyOYL1enYc'},
			{img: Thumbnail5, url: 'UPpLHrqRMXk'},
			{img: Thumbnail6, url: 'X0dRhQrup60'},
			{img: Thumbnail7, url: 'yuLRpFVI8h8'},
			{img: Thumbnail8, url: 'ePCPmuTq3Xo'},
			{img: Thumbnail9, url: 'etUvWeHYMYI'}
		]}
	/>
}

export default Promotional;
import React from 'react';
import ShowcasePage from './ShowcasePage';

import Thumbnail1 from './assets/cinematography/1.png';
import Thumbnail2 from './assets/cinematography/2.png';
import Thumbnail3 from './assets/cinematography/3.jpg';
import Thumbnail4 from './assets/cinematography/4.png';
import Thumbnail5 from './assets/cinematography/5.png';
import Thumbnail6 from './assets/cinematography/6.png';
import Thumbnail7 from './assets/cinematography/7.png';
import Thumbnail8 from './assets/cinematography/8.png';
import Thumbnail9 from './assets/cinematography/9.png';

function Cinematography() {
	return <ShowcasePage
		videoId = "3T1tKCB-E90"
		title="I Stand For Us (2020)"
		description="When hotel workers are faced with unfair working conditions, Naomi works to unite the team in solidarity against destructive zero hour contracts."
		thumbnails={[
			{img: Thumbnail1, url: 'DoQWlviQM_o'},
			{img: Thumbnail2, url: '2J11QgWnsBA'},
			{img: Thumbnail3, url: 'Tf0JHF2NgXE'},
			{img: Thumbnail4, url: 'YFt2ad7OTgo'},
			{img: Thumbnail5, url: 't3S807k4F4s'},
			{img: Thumbnail6, url: 'oVdLlU9FMo8'},
			{img: Thumbnail7, url: 'IosNY_tPxDk'},
			{img: Thumbnail8, url: 'PfeOrLYg8Y8'},
			{img: Thumbnail9, url: '8liFn_Gq7kc'}
		]}
	/>
}

export default Cinematography;
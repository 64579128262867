import React from 'react';
import YouTube from './YouTube';
import Modal from 'react-modal';

import './ShowcasePage.css';

function ShowcasePage(props) {
	const [modalIsOpen, setIsOpen] = React.useState(false);
	const [selectedVideoId, setSelectedVideoId] = React.useState("");

	const customStyles = {
		content: {
			position: 'absolute',
			left: '50%',
			top: '50%',
			bottom: 'auto',
			right: 'auto',
			width: '80vw',
			transform: 'translate(-50%, -50%)',
			overflow: 'visible'
		}
	};


	return (
		<div>
			<Modal
				style={customStyles}
				isOpen={modalIsOpen}
				onRequestClose={() => setIsOpen(false)}
			>
				<p className="showcase--modal-close" onClick={() => setIsOpen(false)}>X</p>
				<YouTube videoId={selectedVideoId} />
			</Modal>
			<div className="showcase">
				<div className="showcase--youtube">
					<YouTube videoId={props.videoId} />
			  	</div>
			  	<div className="showcase--text">
				  	<h1 className="showcase--title">{props.title}</h1>
				  	<p className="showcase--description">{props.description}</p>
			  	</div>
			</div>
			<div className="showcase--thumbnail-grid">
				{props.thumbnails.map(
					thumbnail => <img
						className="showcase--thumbnail"
						onClick={() => { setIsOpen(true); setSelectedVideoId(thumbnail.url) }}
						src={thumbnail.img}
					/>
				)}
		  	</div>
		</div>
	);
}

export default ShowcasePage;
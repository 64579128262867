import React from 'react';
import YouTubeVideoPlayer from 'react-youtube';
import './YouTube.css';

function YouTube(props) {
  const propsCopy = { ...props };
  propsCopy.opts = propsCopy.opts || { playerVars: {} };
  propsCopy.opts.playerVars = propsCopy.opts.playerVars || {};
  return (
    <div className="youtube-container">
      <YouTubeVideoPlayer
        {...propsCopy}
        opts={{
          ...propsCopy.opts,
          playerVars: { ...propsCopy.opts.playerVars, showinfo: 0 },
        }}
        className="youtube-video"
      />
    </div>
  );
}

export default YouTube;

import React from 'react';
import { Route } from 'react-router-dom';
import Main from './Main.js';
import Animation from './Animation';
import Cinematography from './Cinematography';
import Promotional from './Promotional';
import Header from './Header.js';
import Footer from './Footer.js';

import './App.css';

function App() {
  return (
    <div class="app-container">
      <Header />
      <div className="page-container">
	      <Route exact path="/" component={Main} />
	      <Route exact path="/animation" component={Animation} />
	      <Route exact path="/cinematography" component={Cinematography} />
	      <Route exact path="/promotional" component={Promotional} />
      </div>
      <Footer />
    </div>
  );
}

export default App;

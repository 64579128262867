import React from 'react';
import './Footer.css';
import instaImage from './assets/insta.png';
import youtubeImage from './assets/youtube.png';
import fbImage from './assets/facebook.png';

function Footer() {
  return (
    <footer>
      <div className="footer-container footer-jacob">
        <div>
          <a href="https://www.youtube.com/the2awesomeguys">
            <img
              alt="YouTube Logo"
              className="footer-image"
              src={youtubeImage}
            />
          </a>
          <a href="https://www.instagram.com/jacobacrow1/">
            <img
              alt="Instagram Logo"
              className="footer-image"
              src={instaImage}
            />
          </a>
          <a href="https://www.facebook.com/jacobcrowfilms/">
            <img alt="Facebook Logo" className="footer-image" src={fbImage} />
          </a>
        </div>
        <p className="footer-link">
          Contact:{' '}
          <a href="mailto:business@jacobcrow.co.uk">business@jacobcrow.co.uk</a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;

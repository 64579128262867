import React from 'react';
import YouTube from './YouTube';
import './Main.css';

function Main() {
  return <div class="main">
  	<YouTube
  		opts={{ playerVars: { autoplay: true, mute: 1, showinfo: 0 } }}
  		videoId="uaQhskWuDzI" />
  	<p>Filmmaker and animator from the North-East</p>
  </div>
}

export default Main;

import React from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';

function Header() {
  return (
    <div>
      <NavLink to="/">
        <h1 className="header--title">Jacob Crow</h1>
      </NavLink>
      <nav>
        <NavLink class="nav--home-link" to="/">Home</NavLink>
        <NavLink to="/animation">Animation</NavLink>
        <NavLink to="/cinematography">Cinematography</NavLink>
        <NavLink to="/promotional">Promotional</NavLink>
      </nav>
    </div>
  );
}

export default Header;
